import React, { useEffect, useState } from 'react';
import { GetStaticPaths, GetStaticProps, NextPage } from 'next';
import {
  defaultSlugLocales,
  defaultCategoryLocales,
  locales,
  enAndNoLocales,
  Layout,
  localize,
  getValidLocale,
  LocaleType,
  GlobalConfig,
  FrontPageLocalized,
  Product,
  Store,
  Footerv2Props,
} from '@lilbit/shared';
import {
  getSiteConfig,
  getFrontPage,
  getWoocommerceProductPrices,
  getWoocommerceProducts,
  getLilcatProductPrices,
  getFooter,
  getTaxRate,
  getPageSlugs,
  getStores,
  getNewFrontPage,
  getReviews,
} from '../../lib/api';
import RenderSections from '../../components/RenderSections';
import { urlFor } from '../../lib/sanity';
import SimpleBlockContent from '../../components/SimpleBlockContent';
import axios from 'axios';

const IndexPage: NextPage<{
  frontPage: FrontPageLocalized;
  config: GlobalConfig;
  mainProductPrices: any;
  mainProduct: any;
  products: Product[];
  locale: LocaleType;
  footer: Footerv2Props;
  standardTaxRate?: number;
  productTaxRate?: number;
  redirect: boolean;
  categories: Array<{ urlPrefix: string; locale: string }>;
  stores: Store[];
  reviews: any;
}> = ({
  frontPage,
  config,
  mainProductPrices,
  products,
  mainProduct,
  locale,
  footer,
  standardTaxRate,
  productTaxRate,
  stores,
  reviews,
  redirect,
  categories,
}) => {
  const [open, setopen] = useState(false);
  const [basicProductWithPrices, setBasicProductWithPrices] = useState();
  const [premiumProductWithPrices, setPremiumProductWithPrices] = useState();
  const [familyProductWithPrices, setFamilyProductWithPrices] = useState();
  const [forEverCoupon, setForEverCoupon] = useState();

  const setVippsModalOpen = () => {
    setopen(!open);
  };

  const fetchSubscriptionData = async () => {
    axios.get('/api/stripe/get_stripe_subscriptions').then((result)=>{
      const data = result.data;
      if (data?.basicProductWithPrices) {
        setBasicProductWithPrices(data?.basicProductWithPrices);
      }
      if (data?.premiumProductWithPrices) {
        setPremiumProductWithPrices(data?.premiumProductWithPrices);
      }
      if (data?.familyProductWithPrices) {
        setFamilyProductWithPrices(data?.familyProductWithPrices);
      }
      if (data?.forEverCoupon) {
        setForEverCoupon(data?.forEverCoupon);
      }
    });
  }
  useEffect(()=> {
    fetchSubscriptionData();
  }, []);

  footer.SimpleBlockContent = SimpleBlockContent;

  return (
    <Layout
      mainProduct={mainProduct}
      footer={footer}
      config={config}
      metadata={frontPage.description || ''}
      user=""
      title={`${frontPage.title ? frontPage.title : 'lilcat'} | ${
        locale.displayName
      }`}
      imageMetadata={
        frontPage.meta_image ? urlFor(frontPage.meta_image).url() : ''
      }
      setMarginX={false}
      urlFor={urlFor}
      siteName="lilcat"
    >
      {frontPage.content && (
        <RenderSections
          locale={locale}
          sections={frontPage.content}
          mainProduct={mainProduct}
          mainProductPrices={mainProductPrices}
          config={config}
          products={products}
          setVippsModalOpen={setVippsModalOpen}
          standardTaxRate={standardTaxRate}
          productTaxRate={productTaxRate}
          stores={stores}
          reviews={reviews}
          basicProductWithPrices={basicProductWithPrices}
          premiumProductWithPrices={premiumProductWithPrices}
          familyProductWithPrices={familyProductWithPrices}
          forEverCoupon={forEverCoupon}
        />
      )}
      <></>
    </Layout>
  );
};

export const getStaticProps: GetStaticProps = async ({ params }) => {
  const locale = getValidLocale(params.lang as string);
  const sanityLanguageIdentifier = locale.sanityLanguageIdentifier;
  const frontPages = await getNewFrontPage(sanityLanguageIdentifier);
  const frontPage = frontPages[0];
  const localizedFrontPage = localize(frontPage, [sanityLanguageIdentifier]);
  let config = await getSiteConfig(sanityLanguageIdentifier);
  let mainProductPrices = await getLilcatProductPrices();
  const footerData = await getFooter();
  const footer = localize(footerData, [sanityLanguageIdentifier]);
  let counter1 = 0;
  while (!mainProductPrices && counter1 < 10) {
    if (!mainProductPrices) {
      mainProductPrices = await getWoocommerceProductPrices();
      counter1++;
    } else {
      break;
    }
  }
  let mainProduct = await getWoocommerceProducts(true);
  const stores = await getStores(sanityLanguageIdentifier);
  let products = await getWoocommerceProducts();
  let counter2 = 0;
  while (!mainProduct && counter2 < 10) {
    if (!mainProduct || mainProduct?.length <= 0) {
      mainProduct = await getWoocommerceProducts(true);
      counter2++;
    } else {
      break;
    }
  }
  const standardTaxRate = await getTaxRate('standard');
  const productTaxRate =
    mainProduct.tax_class === ''
      ? standardTaxRate
      : await getTaxRate(mainProduct.tax_class);
  config.slugsForAllLocales = defaultSlugLocales;
  config.slugsForAllCategories = defaultCategoryLocales;
  const redirect =
    enAndNoLocales.filter((l) => params.lang != l.urlPrefix).length >
    enAndNoLocales.length - 1;
  const slugsArray = await getPageSlugs();
  const categories = localizePaths(slugsArray, enAndNoLocales);

  const acceptedReviewLanguages = ['no', 'en'];
  const languageIdentifier: string = acceptedReviewLanguages.includes(
    sanityLanguageIdentifier
  )
    ? sanityLanguageIdentifier
    : 'en';
  const reviews = await getReviews(languageIdentifier);

  return {
    props: {
      frontPage: JSON.parse(JSON.stringify(localizedFrontPage)),
      footer,
      locale,
      config,
      mainProductPrices,
      mainProduct: mainProduct || null,
      standardTaxRate,
      productTaxRate,
      products,
      redirect,
      categories,
      stores,
      reviews,
    },
    revalidate: 60 * 60 * 24,
  };
};

export const getStaticPaths: GetStaticPaths = async () => {
  return {
    paths: enAndNoLocales.map((l) => ({
      params: { lang: `${l.urlPrefix}` },
    })),
    fallback: 'blocking',
  };
};

const localizePaths = (slugs, enAndNoLocales) => {
  var localizedPaths = [];
  for (let locale of enAndNoLocales) {
    for (let slugContainer of slugs) {
      let category =
        slugContainer.slug[locale?.sanityLanguageIdentifier]?.current;
      localizedPaths.push({
        urlPrefix: category || null, // added || null to avoid undefined. Should maybe be removed when we get all the languages in sanity
        locale: locale?.sanityLanguageIdentifier,
      });
    }
  }
  return localizedPaths;
};

export default IndexPage;
