import React from 'react';
import { upperFirst } from 'lodash';
import * as SectionComponents from './sections';
import * as Sentry from '@sentry/browser';
import {
  useLocale,
  trimDate,
  Content,
  GlobalConfig,
  LildogProductPrices,
  Locale,
  Product,
  Store,
} from '@lilbit/shared';
import { urlFor } from '../lib/sanity';
import SimpleBlockContent from './SimpleBlockContent';
import { useRouter } from 'next/router'; 

const resolveSections = (section) => {
  const SectionType = upperFirst(section._type);

  const localizedSection =
    SectionComponents[SectionType.replace('Localized', '')];
  if (localizedSection) {
    return localizedSection;
  }

  const SectionReference =
    SectionComponents[SectionType.replace('Reference', '')];
  if (SectionReference) {
    return SectionReference;
  }
  console.warn('Missing section ', section._type);
  Sentry.captureException(
    new Error(`Error in RenderSections, can't find section ${section}.`)
  );
  // eslint-disable-next-line react/display-name
  return () => null;
};

interface Props {
  mainProduct?: Product;
  mainProductPrices?: LildogProductPrices;
  locale: Locale;
  productTaxRate?: number;
  standardTaxRate?: number;
  products?: Product[];
  sections: Content[];
  isApp?: boolean;
  config?: GlobalConfig;
  setVippsModalOpen?: () => void;
  stores?: Store[];
  reviews?: any;
  basicProductWithPrices?: any
  premiumProductWithPrices?: any;
  familyProductWithPrices?: any;
  forEverCoupon?: any;
}

const RenderSections = (props: Props) => {
  const { sections } = props;
  const router = useRouter();
  const additionalProps = {
    urlFor,
    useLocale,
    trimDate,
    SimpleBlockContent,
  };

  if (!sections) {
    Sentry.captureException(
      new Error(
        `Error in RenderSections: Expected sections but no sections present.`
      )
    );
    return <div />;
  }

  const shouldFlexChildren = [
    'nyheter',
    'news',
    'katteraser',
    'cat-breeds',
  ].includes(router.query.category as string);
  return (
    <div
      className={`${
        shouldFlexChildren && 'flex flex-wrap justify-center lg:justify-between'
      }`}
    >
      {sections.map((section) => {
        const SectionComponent = resolveSections(section);
        if (SectionComponent) {
          return (
            <SectionComponent
              {...section}
              {...props}
              {...additionalProps}
              key={section._key}
              siteName="lilcat"
            />
          );
        }
      })}
    </div>
  );
};

export default RenderSections;
